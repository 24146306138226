export const SERVER = 'https://dev.creator-api.expertus.com.ua';
export const API_URL = 'https://dev.creator-api.expertus.com.ua/api';

export const drawerWidth = 280;
export const TOKEN = {
  access: 'YWNjZXNzLXRva2Vu',
  refresh: 'cmVmcmVzaC10b2tlbg',
};
export const MY_EMAIL = process.env.REACT_APP_EMAIL || '';
export const MY_PASS = process.env.REACT_APP_PASS || '';
